import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { BusquedaGeneral } from '../views/app/interfaces/busquedaGeneral.interface';
import { NotificacionExistencia } from '../views/app/models/notificacionExistencia.model';
import { NotificacionVencimiento } from '../views/app/models/notificacionVencimiento.model';
import { NotificacionNav } from '../views/app/models/notificacionesNav.model';
import { GraficaConsulta } from '../views/app/interfaces/graficaConsultas.interface';
import { Router } from '@angular/router';

const base_url= environment.base_url;
@Injectable({
  providedIn: 'root'
})
export class GeneralServiceService {
  
  constructor(private http: HttpClient,private router:Router) {
   }

  Search(parameter){
  let auth_token=localStorage.getItem('access_token'); 
  if(auth_token==null || auth_token == ''){
      this.router.navigateByUrl(`user/login`);
    }
  let options =new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Requested-With':'XMLHttpRequest',
      'Authorization': `Bearer ${auth_token}`
  });
   return this.http.get<BusquedaGeneral[]>(`${base_url}/general/busquedas/${parameter}`,{headers:options});
  }
  
  notificacionesExistencia(){
    let auth_token=localStorage.getItem('access_token'); 
    if(auth_token==null || auth_token == ''){
      this.router.navigateByUrl(`user/login`);
    }
    let options =new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Requested-With':'XMLHttpRequest',
         'Authorization': `Bearer ${auth_token}`
    });
    return this.http.get<NotificacionExistencia[]>(`${base_url}/general/notificacionesExistencia`,{headers:options});
  }
  notificacionesVencimiento(){
     let auth_token=localStorage.getItem('access_token'); 
     if(auth_token==null || auth_token == ''){
      this.router.navigateByUrl(`user/login`);
    }
    let options =new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Requested-With':'XMLHttpRequest',
         'Authorization': `Bearer ${auth_token}`
    });
    return this.http.get<NotificacionVencimiento[]>(`${base_url}/general/notificacionesVencimiento`,{headers:options});
  }
  notificacionesNav(){
    let auth_token=localStorage.getItem('access_token'); 
    if(auth_token==null || auth_token == ''){
      this.router.navigateByUrl(`user/login`);
    }
    let options =new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Requested-With':'XMLHttpRequest',
         'Authorization': `Bearer ${auth_token}`
    });
    return this.http.get<NotificacionNav[]>(`${base_url}/general/notificacionesNav`,{headers:options});
  }
  notificacionesNavAdmin(){
    let auth_token=localStorage.getItem('access_token'); 
    if(auth_token==null || auth_token == ''){
      this.router.navigateByUrl(`user/login`);
    }
    let options =new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Requested-With':'XMLHttpRequest',
         'Authorization': `Bearer ${auth_token}`
    });
    return this.http.get<NotificacionNav[]>(`${base_url}/general/notificacionesNavAdmin`,{headers:options});
  }

  totalProducto(){
    let auth_token=localStorage.getItem('access_token'); 
    if(auth_token==null || auth_token == ''){
      this.router.navigateByUrl(`user/login`);
    }
    let options =new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Requested-With':'XMLHttpRequest',
         'Authorization': `Bearer ${auth_token}`
    });
    return this.http.get(`${base_url}/general/totalesProducto`,{headers:options});
  }
  totalPaciente(){
    let auth_token=localStorage.getItem('access_token'); 
    if(auth_token==null || auth_token == ''){
      this.router.navigateByUrl(`user/login`);
    }
    let options =new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Requested-With':'XMLHttpRequest',
         'Authorization': `Bearer ${auth_token}`
    });
    return this.http.get(`${base_url}/general/totalesPaciente`,{headers:options});
  }
  totalVentas(){
    let auth_token=localStorage.getItem('access_token'); 
    if(auth_token==null || auth_token == ''){
      this.router.navigateByUrl(`user/login`);
    }
    let options =new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Requested-With':'XMLHttpRequest',
         'Authorization': `Bearer ${auth_token}`
    });
    return this.http.get(`${base_url}/general/totalesVentas`,{headers:options});
  }
  totalConsulta(){
    let auth_token=localStorage.getItem('access_token'); 
    if(auth_token==null || auth_token == ''){
      this.router.navigateByUrl(`user/login`);
    }
    let options =new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Requested-With':'XMLHttpRequest',
         'Authorization': `Bearer ${auth_token}`
    });
    return this.http.get(`${base_url}/general/totalesConsultas`,{headers:options});
  }

  graficaConsultas(f1,f2){
    let auth_token=localStorage.getItem('access_token'); 
    if(auth_token==null || auth_token == ''){
      this.router.navigateByUrl(`user/login`);
    }
    let options =new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Requested-With':'XMLHttpRequest',
         'Authorization': `Bearer ${auth_token}`
    });
    return this.http.get<GraficaConsulta[]>(`${base_url}/general/ReporteVentaPorDia/${f1}/${f2}`,{headers:options});
  }
}

<nav class="navbar fixed-top">
    <div class="d-flex align-items-center navbar-left">
        <a (click)="menuButtonClick($event, sidebar.menuClickCount, sidebar.containerClassnames); false;" href="javascript:;" class="menu-button d-none d-md-block">
            <svg class="main" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9 17">
        <rect x="0.48" y="0.5" width="7" height="1" />
        <rect x="0.48" y="7.5" width="7" height="1" />
        <rect x="0.48" y="15.5" width="7" height="1" />
      </svg> {{" "}}
            <svg class="sub" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 17">
        <rect x="1.56" y="0.5" width="16" height="1" />
        <rect x="1.56" y="7.5" width="16" height="1" />
        <rect x="1.56" y="15.5" width="16" height="1" />
      </svg>
        </a>

        <a (click)="mobileMenuButtonClick($event, sidebar.containerClassnames)" href="javascript:;" class="menu-button-mobile d-xs-block d-sm-block d-md-none">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 17">
        <rect x="0.5" y="0.5" width="25" height="1" />
        <rect x="0.5" y="7.5" width="25" height="1" />
        <rect x="0.5" y="15.5" width="25" height="1" />
      </svg>
        </a>

        <div class="search">
            <input placeholder="Buscar..." [(ngModel)]="searchKey" (keyup)="search(searchTxt.value)" #searchTxt>
            <span class="search-icon">
        <i class="simple-icon-magnifier"></i>
      </span>
        </div>


    </div>

    <a class="navbar-logo" [routerLink]="adminRoot">
        <!-- <span class="logo d-none d-xs-block"></span>
        <span class="d-block d-xs-none"></span> -->
        <!-- //<h2>El Medico En Casa</h2> -->
    </a>

    <div class="navbar-right">
        <div class="header-icons d-inline-block align-middle">

            <div class="d-none d-md-inline-block align-text-bottom mr-1">
                <div class="custom-control custom-switch" tooltip="Dark Mode" placement="left">
                    <input type="checkbox" class="custom-control-input" id="darkModeSwitch" [checked]="isDarkModeActive" (change)="onDarkModeChange($event)">
                    <label class="custom-control-label" for="darkModeSwitch"></label>
                </div>
            </div>

            <div class="position-relative d-inline-block">
                <div dropdown>
                    <button id="button-basic" dropdownToggle type="button" id="notificationButton" class="header-icon btn btn-empty">
            <i class="simple-icon-bell"></i>
            <span class="count">{{countNotificaiones}}</span>
          </button>
                    <div id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-right mt-3" id="notificationDropdown">
                        <perfect-scrollbar>
                            <div class="d-flex flex-row mb-3 pb-3 border-bottom" *ngFor="let item of notificaciones">
                                <i class="iconsminds-danger" *ngIf="item.tipo==1" (click)="productos(item.id)"></i>
                                <i class="simple-icon-calendar" *ngIf="item.tipo==2" (click)="productos(item.id)"></i>
                                <i class="iconsminds-male-2" *ngIf="item.tipo==3" (click)="account()"></i>
                                <i class="iconsminds-male-2" *ngIf="item.tipo==4" (click)="account()"></i>
                                <div class="pl-3">
                                    <a>
                                        <p class="font-weight-medium mb-1" *ngIf="item.tipo==1" (click)="productos(item.id)">{{item.nombrecomercial}}</p>
                                        <p class="font-weight-medium mb-1" *ngIf="item.tipo==2" (click)="productos(item.id)">{{item.nombrecomercial}}</p>
                                        <p class="font-weight-medium mb-1" *ngIf="item.tipo==3" (click)="account()">{{item.nombrecomercial}}</p>
                                        <p class="font-weight-medium mb-1" *ngIf="item.tipo==4" (click)="account()">{{item.nombrecomercial}}</p>
                                        <p class="text-muted mb-0 text-small" *ngIf="item.tipo==1" (click)="productos(item.id)">Producto Bajo Inventario</p>
                                        <p class="text-muted mb-0 text-small" *ngIf="item.tipo==2" (click)="productos(item.id)">Producto Proximo a Vencer</p>
                                        <p class="text-muted mb-0 text-small" *ngIf="item.tipo==3" (click)="account()">Aprobacion</p>
                                        <p class="text-muted mb-0 text-small" *ngIf="item.tipo==4" (click)="account()">Contraseña</p>
                                    </a>
                                </div>
                            </div>
                        </perfect-scrollbar>
                    </div>
                </div>
            </div>

            <button class="header-icon btn btn-empty d-none d-sm-inline-block" type="button" id="fullScreenButton" (click)="fullScreenClick()">
        <i *ngIf="!isFullScreen" class="simple-icon-size-fullscreen"></i>
        <i *ngIf="isFullScreen" class="simple-icon-size-actual"></i>
      </button>

        </div>

        <div class="user d-inline-block" dropdown>
            <button class="btn btn-empty p-0" type="button" dropdownToggle>
        <span class="name">{{name}}</span>
        <span>
          <img alt="Profile Picture" src="/assets/img/login/loginheader2.jpg" />
        </span>
      </button>

            <div class="dropdown-menu dropdown-menu-right mt-3" *dropdownMenu role="menu">
                <a class="dropdown-item c-pointer" (click)="account()">Account</a>
                <li class="divider dropdown-divider"></li>
                <a class="dropdown-item c-pointer" (click)="onSignOut()">Cerrar</a>
            </div>
        </div>

    </div>
</nav>
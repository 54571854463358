// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


export const environment = {
  production: false,
  adminRoot: '/app',
  defaultMenuType: 'menu-default',
  subHiddenBreakpoint: 1440,
  menuHiddenBreakpoint: 768,
  themeColorStorageKey: 'vien-themecolor',
  isMultiColorActive: true,
  base_url: 'https://inventarioapp-3jtak.ondigitalocean.app/backend/api',
  //base_url: 'http://127.0.0.1:8000/api',
  authApiKey:'TDSXwdval8l8N92WZDxhc8w91Rp8Q48KW79b8OWU',
  authClientId:2,
  defaultColor: 'light.blueyale',
  isDarkSwitchActive: true,
  defaultDirection: 'ltr',
  themeRadiusStorageKey: 'vien-themeradius',
  isAuthGuardActive: true,
  
};

import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders  } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Usuario } from '../views/app/models/usuario.model';
import { tap } from 'rxjs/operators';

const base_url= environment.base_url;
const apiKey= environment.authApiKey;
const apiClient=environment.authClientId;
@Injectable({
  providedIn: 'root'
})
export class AuthServiceInventario {

  options:any;
  Usuario:Usuario;
  constructor(private http: HttpClient) { 
    
  }

  aprobacionesAdmin(){
    let auth_token=localStorage.getItem('access_token'); 
     var options =new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Requested-With':'XMLHttpRequest',
        'Authorization': `Bearer ${auth_token}`
     });
    return this.http.get<Usuario[]>(`${base_url}/general/userAprobaciones`,{headers:options});
  }

  aprobarUsuario(usuario){
   let auth_token=localStorage.getItem('access_token'); 
     var options =new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Requested-With':'XMLHttpRequest',
        'Authorization': `Bearer ${auth_token}`
     });

     return this.http.post(`${base_url}/general/aprobarUsuario`,usuario,{headers:options});
  }
  resetPass(usuario){
   let auth_token=localStorage.getItem('access_token'); 
     var options =new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Requested-With':'XMLHttpRequest',
         'Access-Control-Allow-Origin': '*',
         'Authorization': `Bearer ${auth_token}`
     });

     return this.http.post(`${base_url}/general/resetPass`,usuario,{headers:options});
  }
  cambiarPassword(usuario){
   let auth_token=localStorage.getItem('access_token'); 
     var options =new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Requested-With':'XMLHttpRequest',
        'Authorization': `Bearer ${auth_token}`
     });

     return this.http.post(`${base_url}/general/cambiarPassword`,usuario,{headers:options});
  }

  login(Credenciales){
      var options =new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Requested-With':'XMLHttpRequest',
     });
     return this.http.post(`${base_url}/auth/login`,{
       grant_type: 'password',
       client_id: apiClient,
       client_secret: apiKey,
       email: Credenciales['user'],
       password: Credenciales['password'],
       remember_me: false
      },{headers:options}).pipe( tap((resp:any) =>{
    
      }));
  }

  logOut(){
     let auth_token=localStorage.getItem('access_token'); 
     var options =new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Requested-With':'XMLHttpRequest',
        'Authorization': `Bearer ${auth_token}`
     });
    
     return this.http.get(`${base_url}/auth/logout`,{headers:options});

  }

  requestResetPassword(email){
     var options =new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Requested-With':'XMLHttpRequest',
     });

     return this.http.post(`${base_url}/auth/resetPassword`,email,{headers:options})
  }

  getUser(){
     let auth_token=localStorage.getItem('access_token'); 
     let user=localStorage.getItem('tup'); 
     var options =new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Requested-With':'XMLHttpRequest',
         'Authorization': `Bearer ${auth_token}`
     });
    

     return this.http.get<Usuario>(`${base_url}/auth/user/${user}`,{headers:options});
  }

  saveUser(user:Usuario){
    this.Usuario=user;
  }

  registroUsuario(usuario){
   return this.http.post(`${base_url}/auth/Registro`,usuario);
  }
}

import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { Subscription } from 'rxjs';
import { SidebarService, ISidebar } from '../sidebar/sidebar.service';
import { Router } from '@angular/router';
import { LangService, Language } from 'src/app/shared/lang.service';
import { environment } from 'src/environments/environment';
import { getThemeColor, setThemeColor } from 'src/app/utils/util';
import { GeneralServiceService } from '../../../services/general-service.service';
import { NotificacionNav } from '../../../views/app/models/notificacionesNav.model';
import { AuthServiceInventario } from '../../../services/auth-service.service';
import { Usuario } from '../../../views/app/models/usuario.model';

@Component({
  selector: 'app-topnav',
  templateUrl: './topnav.component.html',
})
export class TopnavComponent implements OnInit, OnDestroy {
  adminRoot = environment.adminRoot;
  sidebar: ISidebar;
  subscription: Subscription;
  languages: Language[];
  currentLanguage: string;
  isSingleLang;
  isFullScreen = false;
  isDarkModeActive = false;
  searchKey = '';
  public countNotificaiones:number=0;
  public notificaciones:NotificacionNav[]=[];
  public name;
  public usuario:Usuario;
  constructor(
    private sidebarService: SidebarService,
    private router: Router,
    private langService: LangService,
    private service:GeneralServiceService,
    private serviceAuth:AuthServiceInventario
  ) {
    this.getUser();
    this.languages = this.langService.supportedLanguages;
    this.currentLanguage = this.langService.languageShorthand;
    this.isSingleLang = this.langService.isSingleLang;
    this.isDarkModeActive = getThemeColor().indexOf('dark') > -1 ? true : false;
  }
  getUser(){
    this.serviceAuth.getUser().subscribe(resp=>{
      this.name= resp['name']
      if(resp.role==1){
        this.getNotificaciones(1);
      }
      if(resp.role==0){
        this.getNotificaciones(0);
      }
    });
  }

  account(){
    this.router.navigateByUrl('app/account');
  }
  onDarkModeChange(event): void {
    let color = getThemeColor();
    if (color.indexOf('dark') > -1) {
      color = color.replace('dark', 'light');
    } else if (color.indexOf('light') > -1) {
      color = color.replace('light', 'dark');
    }
    setThemeColor(color);
    setTimeout(() => {
      window.location.reload();
    }, 200);
  }

  fullScreenClick(): void {
    if (document.fullscreenElement) {
      document.exitFullscreen();
    } else {
      document.documentElement.requestFullscreen();
    }
  }

  @HostListener('document:fullscreenchange', ['$event'])
  handleFullscreen(event): void {
    if (document.fullscreenElement) {
      this.isFullScreen = true;
    } else {
      this.isFullScreen = false;
    }
  }
 
  getNotificaciones(role){
    if(role==1){
      this.service.notificacionesNavAdmin().subscribe(resp=>{
      this.countNotificaiones= resp.length;
      this.notificaciones=resp;
     // console.log(this.notificaciones);
      });
    }
    if(role==0){
      this.service.notificacionesNav().subscribe(resp=>{
      this.countNotificaiones= resp.length;
      this.notificaciones=resp;
      //console.log(this.notificaciones);
      });
    }
    
  }

  async ngOnInit(): Promise<void> {

    this.subscription = this.sidebarService.getSidebar().subscribe(
      (res) => {
        this.sidebar = res;
      },
      (err) => {
        console.error(`An error occurred: ${err.message}`);
      }
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  menuButtonClick = (
    e: { stopPropagation: () => void },
    menuClickCount: number,
    containerClassnames: string
  ) => {
    if (e) {
      e.stopPropagation();
    }

    setTimeout(() => {
      const event = document.createEvent('HTMLEvents');
      event.initEvent('resize', false, false);
      window.dispatchEvent(event);
    }, 350);

    this.sidebarService.setContainerClassnames(
      ++menuClickCount,
      containerClassnames,
      this.sidebar.selectedMenuHasSubItems
    );
  }

  mobileMenuButtonClick = (
    event: { stopPropagation: () => void },
    containerClassnames: string
  ) => {
    if (event) {
      event.stopPropagation();
    }
    this.sidebarService.clickOnMobileMenu(containerClassnames);
  }

  onSignOut(): void {
    this.serviceAuth.logOut().subscribe(() => {
      localStorage.removeItem('access_token');
      localStorage.removeItem('tup');
      this.router.navigate(['/user/login']);
    });
  }

  

  searchAreaClick(event): void {
    event.stopPropagation();
  }
  // searchClick(event): void {
  //   if (window.innerWidth < environment.menuHiddenBreakpoint) {
  //     let elem = event.target;
  //     if (!event.target.classList.contains('search')) {
  //       if (event.target.parentElement.classList.contains('search')) {
  //         elem = event.target.parentElement;
  //       } else if (
  //         event.target.parentElement.parentElement.classList.contains('search')
  //       ) {
  //         elem = event.target.parentElement.parentElement;
  //       }
  //     }

  //     if (elem.classList.contains('mobile-view')) {
  //      // this.search();
  //       elem.classList.remove('mobile-view');
  //     } else {
  //       elem.classList.add('mobile-view');
  //     }
  //   } else {
  //    // this.search();
  //   }
  //   event.stopPropagation();
  // }

  search(parameter){
    if (this.searchKey && this.searchKey.length > 1) {
     
       this.router.navigateByUrl(`app/Busqueda/${parameter}`);
          // this.searchKey = '';
    }
  }

  @HostListener('document:click', ['$event'])
  handleDocumentClick(event): void {
    const input = document.querySelector('.mobile-view');
    if (input && input.classList) {
      input.classList.remove('mobile-view');
    }
    this.searchKey = '';
  }

  productos(id){
   this.router.navigateByUrl(`app/producto/${id}`);
  }
}
